<template>
    <div id="send_order">
        <div class="send_buy_block">
            <div class="title">第一步：选择数据来源平台</div>
            <div class="list">
                <ul class="clearfix">
                    <li @click="changPlatform(2)" :class="platform==2?'red':''">
                        淘宝/天猫
                        <img v-if="platform==2" src="@/assets/img/09.png" alt="">
                    </li>
                    <li @click="changPlatform(3)" :class="platform==3?'red':''">
                        京东
                        <img v-if="platform==3" src="@/assets/img/09.png" alt="">
                    </li>
                    <li @click="changPlatform(4)" :class="platform==4?'red':''">
                        拼多多
                        <img v-if="platform==4" src="@/assets/img/09.png" alt="">
                    </li>
                    <li @click="changPlatform(5)" :class="platform==5?'red':''">
                        抖音
                        <img v-if="platform==5" src="@/assets/img/09.png" alt="">
                    </li>
                    <li @click="changPlatform(1)" :class="platform==1?'red':''">
                        其他
                        <img v-if="platform==1" src="@/assets/img/09.png" alt="">
                    </li>
                </ul>
            </div>
        </div>
        <div class="send_buy_block">
            <div class="title">第二步：录入收件人信息</div>
            <div class="list">
                <ul class="clearfix">
                    <li @click="model=1" :class="model==1?'red':''">
                        手动输入
                        <img v-if="model==1" src="@/assets/img/09.png" alt="">
                    </li>
                </ul>
            </div>
        </div>
        <div class="send_buy_block" v-if="(platform == 4 && model == 4) || (platform == 2 && model == 5) || (platform ==5 && model==6)">
            <div class="title">第三步：选择自动发货</div>
            <div class="list">
                <div class="packageList">
                    <el-select size="small" v-model="houseId" clearable placeholder="请选择店铺（自动发货）">
                        <el-option
                        v-for="item in houseList1"
                        :label="item.name"
                        :value="item.id">
                        </el-option>
                    </el-select>
                    <el-button size="small" @click="dialogVisible1 = true">新增店铺</el-button>
                    <el-button size="small" @click="toAuth">授权</el-button>
                    <el-button size="small" @click="dialogVisible2 = true">查看店铺列表</el-button>
                    <span>（选择后可到订单列表进行一键发货）</span>
                </div>
                <div class="pdd_order_btn">
                    <el-radio-group v-model="pddRadio" @change="pddRadioChange">
                        <el-radio label="1">按订单号筛选</el-radio>
                        <el-radio label="2">按时间筛选</el-radio>
                    </el-radio-group>
                </div>
                <div class="pdd_order_btn text" v-if="model == 4&&pddRadio==1">1 建议订单号不要超过100条,购买服务后，后台不要点击三行打单的去使用，如果点了需来这里重新授权
                    </br>2 如果店铺有在时间范围内的订单，但是查不出来的，请重新授权
                    </br>3 不支持拼多多一键下单的仓库：<span v-for="item in storeList2" v-if="item.pddStatus!=1">{{item.name}}</span>
                </div>
                <div class="pdd_order_btn text" v-if="model == 4&&pddRadio==2">1 时间筛选会查出24小时范围内的最多100条待发货订单,购买服务后，后台不要点击三行打单的去使用，如果点了需来这里重新授权
                    </br>2 如果店铺有在时间范围内的订单，但是查不出来的，请重新授权
                    </br>3 不支持拼多多一键下单的仓库：<span v-for="item in storeList2" v-if="item.pddStatus!=1">{{item.name}}</span>
                </div>
                <div class="pdd_order_btn text" v-if="model == 5&&pddRadio==1">1 建议订单号不要超过100条
                    </br>2 该方式会查近7天的订单，如果店铺有在时间范围内的订单，但是查不出来的，请重新授权
                    </br>3 不支持淘宝一键下单的仓库：<span v-for="item in storeList2" v-if="item.tbStatus!=1">{{item.name}}</span>
                </div>
                <div class="pdd_order_btn text" v-if="model == 5&&pddRadio==2">1 查询的时间跨度不能大于14天
                    </br>2 如果店铺有在时间范围内的订单，但是查不出来的，请重新授权
                    </br>3 最多只查出1000条订单，如超出1000条，请先把前1000条订单发货后再去查询剩余的订单
                    </br>4 不支持淘宝一键下单的仓库：<span v-for="item in storeList2" v-if="item.tbStatus!=1">{{item.name}}</span>
                </div>
                <div class="pdd_order_btn text" v-if="model == 6&&pddRadio==1">1 建议订单号不要超过100条
                    </br>2 如果店铺有订单，但是查不出来的，请重新授权</div>
                <div class="pdd_order_btn text" v-if="model == 6&&pddRadio==2">1 建议订单号不要超过100条
                    </br>2 如果店铺有在时间范围内的订单，但是查不出来的，请重新授权
                </div>
                <div class="pdd_order_btn text" style="margin: 0;" v-if="model == 6">
                    <span>3 不支持抖音一键下单的仓储：</span>
                    <span v-for="item in storeList2" v-if="item.douyinStatus!=1">{{item.name}}&nbsp;&nbsp;</span>
                </div>
              
                <div class="pdd_order_btn" v-if="(model == 4&&pddRadio==1) || (model == 5&&pddRadio==1) || (model == 6&&pddRadio==1)">
                    <div class="remark" style="width: 360px; height: 160px; margin-top: 10px;">
                        <textarea v-model="stringRule1" placeholder="一行一条订单号" style="height:160px"></textarea>
                    </div>
                    <div class="btn" @click="submitText1">完成添加</div>
                </div>
                <div class="pdd_order_btn" v-if="(model == 4&&pddRadio==2) || (model == 5&&pddRadio==2) || (model == 6&&pddRadio==2)">
                    <el-date-picker
                        @change = 'timeChange'
                        size="small"
                        v-model="timeArr"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                    <el-input  size="small" style="width: 110px; " placeholder="输入卖家备注" v-model="remark2"></el-input>
                    <!-- <el-input  size="small" style="width: 250px; " placeholder="输商品价格区间，用-隔开" v-model="goodPrice"></el-input> -->
                    <el-input  size="small" style="width: 100px; " placeholder="商品最小值" v-model="samllGoodPrice"></el-input>
                    <el-input  size="small" style="width: 100px; " placeholder="商品最大值" v-model="largeGoodPrice"></el-input>
                    <el-button size="small" @click="getPDDOrder" :loading="pddLoading" v-if="model == 4">获取拼多多订单列表</el-button>
                    <el-button size="small" @click="getTBOrder" :loading="pddLoading" v-if="model == 5">获取淘宝订单列表</el-button>
                    <el-button size="small" @click="getDouyinOrder" :loading="douyinLoading" v-if="model == 6">获取抖音订单列表</el-button>
                </div>
                <div class="chose_goods_list" v-if="taskList4.length>0 && ((model == 4 && pddRadio==1) || (model == 5 && pddRadio==1) || (model == 6 && pddRadio==1))">
                    <el-button type="danger" size="small" style="margin-top: 5px;margin-bottom: 5px" @click="deleteDataAkey()">一键删除错误订单</el-button>
                    <el-button type="success" size="small" style="margin-top: 5px;margin-bottom: 5px" @click="sortDataAkey()">有误排序</el-button>
                    <table>
                        <tr>
                            <td style="flex: 0 0 45px;padding-right:10px">#</td>
                            <td>订单编号</td>
                            <td>姓名</td>
                            <td>电话</td>
                            <td>地址</td>
                            <td>状态</td>
                            <td>操作</td>
                        </tr>
                        <tr v-for="(item,index) in taskList4">
                            <td style="padding: 15px 10px; line-height: 22px;flex:0 0 45px">{{index}}</td>
                            <td style="padding: 15px 10px; line-height: 22px;">{{item.conlist[0].orderNum}}</td>
                            <td style="padding: 15px 10px; line-height: 22px;">{{item.conlist[0].consignee}}</td>
                            <td style="padding: 15px 10px; line-height: 22px;">{{item.conlist[0].consigneePhone}}</td>
                            <td style="padding: 15px 10px; line-height: 22px;">
                                {{item.conlist[0].province}}{{item.conlist[0].city}}{{item.conlist[0].county}}{{item.conlist[0].address}}
                            </td>
                            <td style="padding: 15px 10px; line-height: 25px; ">
                                <div v-html="item.html"
                                    style="width:200px;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;">
                                </div>
                            </td>
                            <td style="padding: 15px 10px; line-height: 22px;">
                                <el-button type="danger" size="small" style="margin-top: 10px;" @click="deleteData(index)">删除</el-button>
                                <el-button type="default" size="small" style="margin-top: 10px;" @click="edit(item,index)">编辑</el-button>
                            </td>
                        </tr>
                    </table>
                </div>

                <div class="chose_goods_list" v-if="taskList4.length>0 && ((model == 4 && pddRadio==2) || (model == 5 && pddRadio==2) || (model == 6 && pddRadio==2))">
                    <el-button type="danger" size="small" style="margin-top: 5px;margin-bottom: 5px" @click="deleteDataAkey()">一键删除错误订单</el-button>
                    <el-button type="success" size="small" style="margin-top: 5px;margin-bottom: 5px" @click="sortDataAkey()">有误排序</el-button>
                    <el-table :data="taskList4">
                        <el-table-column label="#" width="40">
                            <template slot-scope="scope" style="text-align:center">
                                {{scope.$index}}
                            </template>
                        </el-table-column>
                        <el-table-column label="订单编号" width="170">
                            <template slot-scope="scope">
                                {{scope.row.conlist[0].orderNum}}
                            </template>
                        </el-table-column>
                        <el-table-column label="姓名">
                            <template slot-scope="scope">
                                {{scope.row.conlist[0].consignee}}
                            </template>
                        </el-table-column>
                        <el-table-column label="电话" width="110px">
                            <template slot-scope="scope">
                                {{scope.row.conlist[0].consigneePhone}}
                            </template>
                        </el-table-column>
                        <el-table-column label="地址" width="200px">
                            <template slot-scope="scope">
                                {{scope.row.conlist[0].province}}{{scope.row.conlist[0].city}}{{scope.row.conlist[0].county}}{{scope.row.conlist[0].address}}
                            </template>
                        </el-table-column>
                        <el-table-column label="备注" v-if="model == 4 || model == 6">
                            <template slot-scope="scope">
                                {{scope.row.conlist[0].remark}}
                            </template>
                        </el-table-column>
                        <el-table-column label="备注" v-if="model == 5">
                            <template slot-scope="scope">
                                {{scope.row.conlist[0].sellerMemo}}
                            </template>
                        </el-table-column>
                        <el-table-column label="商品价格" align="center">
                            <template slot-scope="scope" >
                                {{scope.row.conlist[0].goodsPrice}}
                            </template>
                        </el-table-column>
                        <el-table-column label="状态" fixed="right">
                            <template slot-scope="scope">
                                <div v-html="scope.row.html"></div>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" fixed="right" width="150px">
                            <template slot-scope="scope">
                                <el-button type="danger" size="small" style="margin-top: 10px;" @click="deleteData(scope.$index)">删除</el-button>
                                <el-button type="default" size="small" style="margin-top: 10px;" @click="edit(scope.row,scope.$index)">编辑</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>

            </div>
        </div>
        <!-- 手动输入 -->
        <div v-if="model == 1" class="send_buy_block">
            <div class="tip">
                <b>格式要求：</b><br>
                <font style="color: rgb(255, 70, 78); font-weight: bold;">订单编号,姓名,电话,地址 按顺序填写,中间用逗号分隔。一次可以输入多个收件人信息，一个收件人信息一行输入。</font><br>
                示例：<br>
                <font style="color: rgb(255, 70, 78); font-weight: bold;">800000000000,张三,13900000000,浙江省 杭州市 西湖区 江南大道88号</font><br>
                订单编号：淘、猫、京、拼等平台订单编号，如无订单编号可随机填写。<br>
                如遇东莞市、中山市、天门市、三沙市、儋州市、嘉峪关市等没有区的城市，区域可设置为“其他区”<br>
                出现地址错误，点击地址编辑，完善省市区等信息即可。<br><br>
                
            </div>
            <div class="chose_goods_list" v-if="taskList4.length>0">
                <el-button type="danger" size="small" style="margin-top: 5px;margin-bottom: 5px" @click="deleteDataAkey()">一键删除错误订单</el-button>
                <el-button type="success" size="small" style="margin-top: 5px;margin-bottom: 5px" @click="sortDataAkey()">有误排序</el-button>
                <table>
                    <tr>
                        <td style="flex: 0 0 45px;padding-right:10px">#</td>
                        <td>订单编号</td>
                        <td>姓名</td>
                        <td>电话</td>
                        <td>地址</td>
                        <td>状态</td>
                        <td>操作</td>
                    </tr>
                    <tr v-for="(item,index) in taskList4">
                        <td style="padding: 15px 10px; line-height: 22px;flex:0 0 45px">{{index}}</td>
                        <td style="padding: 15px 10px; line-height: 22px;">{{item.conlist[0].orderNum}}</td>
                        <td style="padding: 15px 10px; line-height: 22px;">{{item.conlist[0].consignee}}</td>
                        <td style="padding: 15px 10px; line-height: 22px;">{{item.conlist[0].consigneePhone}}</td>
                        <td style="padding: 15px 10px; line-height: 22px;">
                            {{item.conlist[0].province}}{{item.conlist[0].city}}{{item.conlist[0].county}}{{item.conlist[0].address}}
                        </td>
                        <td style="padding: 15px 10px; line-height: 22px; ">
                            <el-tooltip class="item" effect="dark" :content="getHtmlContent(item.html)"
                                placement="top-end">
                                <div style="width: 5rem; overflow: hidden; text-overflow:ellipsis; white-space: nowrap; text-align: center;"
                                    v-html="item.html"></div>
                            </el-tooltip>
                        </td>
                        <td style="padding: 15px 10px; line-height: 22px;">
                            <el-button type="danger" size="small" style="margin-top: 10px;" @click="deleteData(index)">删除</el-button>
                            <el-button type="default" size="small" style="margin-top: 10px;" @click="edit(item,index)">编辑</el-button>
                        </td>
                    </tr>
                </table>
            </div>
            <!-- <div class="input_put_block" v-if="showTool">
                <div class="put_item">
                    <input type="text" v-model="editTool.orderNum" class="edit_input" placeholder="订单编号">
                    <input type="text" v-model="editTool.consignee" class="edit_input" placeholder="姓名">
                    <input type="text" v-model="editTool.consigneePhone" class="edit_input" placeholder="电话">
                    <input type="text" v-model="editTool.province" class="edit_input" placeholder="省">
                    <input type="text" v-model="editTool.city" class="edit_input" placeholder="市">
                    <input type="text" v-model="editTool.county" class="edit_input" placeholder="区">
                    <input type="text" v-model="editTool.address" class="edit_input" placeholder="详细地址">
                    <el-button size="small" type="danger" @click="checkEdit">确认修改</el-button>
                    <el-button size="small" type="defauil">取消</el-button>
                </div>
            </div> -->
            <p style="margin-top: 10px; font-size: 14px;">收件人信息</p>
            <div class="remark" style="width: 938px; height: 160px; margin-top: 10px;">
                <textarea placeholder="订单编号,姓名,电话,地址
订单编号,姓名,电话,地址
订单编号,姓名,电话,地址
订单编号,姓名,电话,地址
订单编号,姓名,电话,地址" style="height: 152px; margin: 0px; width: 943px;"
                v-model="recipientListStr">
                </textarea>
            </div>
            <div class="btn" @click="submitText">完成添加</div>
        </div>
        <div v-if="model == 1 || model == 4 || model == 5 || model==6" id="add_goods_btn" class="send_buy_block">
            <div class="title">最后一步：选择赠送礼品</div>
            <div class="chose_goods_list" v-if="shopCart1.name">
                <table>
                    <tr>
                        <td>商品名称</td>
                        <td>单价</td>
                        <td>采购数量</td>
                        <td>单重量</td>
                    </tr>
                    <tr>
                        <td>
                            <dl class="table_dl">
                                <dt>
                                    <div class="el-image" style="width: 50px; height: 50px;">
                                        <img :src="shopCart1.cover | fullPath" class="el-image__inner">
                                    </div>
                                </dt>
                                <dd class="table_dl_dd_all">{{shopCart1.name}}</dd>
                            </dl>
                        </td>
                        <td>{{(shopCart1.cost + shopCart1.profit).toFixed(2)}}</td>
                        <td>{{shopCart1.num}}</td>
                        <td>{{shopCart1.weight}}</td>
                    </tr>
                </table>
                <div class="chose_goods_edit_btn">
                    <el-button type="danger" size="small" icon="el-icon-edit" @click="showDialog">编辑商品</el-button>
                </div>
            </div>
            <div class="chose_goods" @click="showDialog()" v-else>
                <img width="100px" height="100px" src="@/assets/img/add.png" alt="">
                <p>添加商品</p>
            </div>
            <div class="remark">
                <div class="select_item">
                    <label>财务备注：</label>
                    <el-input size="small" v-model="remark"></el-input>
                </div>
            </div>
            <div :class="['btn',checkState?'':'display']" @click="checkAreaSubmit">确定订单 ￥{{sum.toFixed(2)}}</div>
            <p style="color: rgb(153, 153, 153); font-size: 12px; line-height: 35px;">
                总共 <font>{{taskList4.length}}</font>
                个 收件人，每位价格 ￥
                <font color="#333">{{sumPrice}}</font>
            </p>
        </div>
        <!-- 模板导入 -->
        <div v-if="model == 2" class="send_buy_block clearfix">
            <div class="upload-demo">
                <el-upload
                    drag
                    action=""
                    :limit="1"
                    name="file1"
                    :on-exceed="handleExceed"
                    :file-list="flist1"
                    accept=".xlsx,.csv,.xls"
                    :http-request="getFile"
                    ref="upload">
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                </el-upload>
            </div>
            <div class="tip" style="float: left; width: 500px; margin-left: 20px; margin-top: 20px;"><b>注意事项:</b><br>
                1、仅支持CSV、XLXS、XLS文件，文件大小限制在2M以内<br>
                2、【淘宝格式】说明 ：
淘宝导出订单表格（因淘宝导出带有密码），导出后复制所有
内容至新建表格里，再上传新表格，否则无法上传！<br>
3、点击下方按钮下载通用模版表格，必须按照模板格式录入
收货信息，否则无法上传！<br>
                <!-- <a target="_blank" href="https://v.youku.com/v_show/id_XNDYxNjc2ODE2MA==.html?spm=a2h0c.8166622.PhoneSokuUgc_1.dtitle" style="color: rgb(64, 158, 255);">3、视频教程</a><br>  -->
                <a href="/business/导入模板(all).rar" style="color: rgb(255, 70, 78);">4、导入模版下载</a><br>
            </div>
            <div style="clear: both;"></div>
            <div class="btn" @click="uploadFile">确定上传</div>
        </div>
        <!-- 智能筛选 -->
        <div v-if="model == 3" class="send_buy_block clearfix">
            <div class="upload-demo">
                <el-upload
                    drag
                    action=""
                    :limit="1"
                    :file-list="flist2"
                    name="file"
                    ref="upload1"
                    :on-exceed="handleExceed"
                    :http-request="getFile2"
                    accept=".xlsx,.csv,.xls">
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                </el-upload>
            </div>
            <div class="tip" style="float: left; width: 500px; margin-left: 20px; margin-top: 20px;"><b>注意事项:</b><br>
                1、仅支持CSV、XLXS、XLS文件，文件大小限制在2M以内<br>
                2、【淘宝格式】说明 ：
淘宝导出订单表格（因淘宝导出带有密码），导出后复制所有
内容至新建表格里，再上传新表格，
否则无法上传！<br>
3、点击下方按钮下载通用模版表格，必须按照模板格式录入
收货信息，否则无法上传！<br>
                <!-- <a target="_blank" href="https://v.youku.com/v_show/id_XNDYxNjc2ODE2MA==.html?spm=a2h0c.8166622.PhoneSokuUgc_1.dtitle" style="color: rgb(64, 158, 255);">3、视频教程</a><br>  -->
                <a href="/business/导入模板(all).rar" style="color: rgb(255, 70, 78);">4、导入模版下载</a><br>
            </div>
            <div style="clear: both;"></div>
            <div class="list clearfix">
                <ul>
                    <li @click="way = 1" :class="way == 1?'red':''">
                        订单号筛选
                        <img v-if="way == 1" src="@/assets/img/09.png" alt="">
                    </li>
                    <li @click="way = 2" :class="way == 2?'red':''">
                        收件人筛选
                        <img v-if="way == 2" src="@/assets/img/09.png" alt="">
                    </li>
                </ul>
            </div>
            <p style="margin-top: 10px; font-size: 14px;">筛选条件</p>
            <div class="remark" style="width: 360px; height: 160px; margin-top: 10px;">
                <textarea v-model="stringRule" placeholder="一行一条" style="height:160px"></textarea>
            </div>
            <div class="btn" @click="uploadFile2">确定筛选</div>
            <div class="dont" @click="dialogVisible3 = true">确认筛选点击无反应怎么办?</div>
        </div>
        <el-dialog
            title="添加商品"
            :visible.sync="dialogVisible"
            @close="closeDialog2"
            width="1200px">
            <div class="chose_goods clearfix">
                <div class="left_item">
                    <div class="search_keywrod">
                        <div class="word">
                            <el-input v-model="formData.keyWord" size="small" placeholder="请输入商品关键词"></el-input>
                        </div>
                        <div class="search_btn">
                            <el-button icon="el-icon-search" size="small" type="info" @click="search">搜索</el-button>
                        </div>
                        <div class="chose red">商城产品</div>
                    </div>
                    <div class="goods_params">
                        <div class="block clearfix">
                            <div class="left_info">分类筛选：</div>
                            <ul>
                                <li @click="formData.type = '',search()" :class="formData.type == '' ? 'active':''" >全部分类</li>
                                <li @click="formData.type = item.id,search()" :class="formData.type == item.id ? 'active':''" v-for="item in typeList">{{item.name}}</li>
                            </ul>
                        </div>
                        <div class="block clearfix">
                            <div class="left_info">仓储：</div>
                            <ul>
                                <li @click="formData.storeId = item.id,search(),getExpressSheet(item.id)" :class="formData.storeId == item.id ? 'active':''" v-for="item in storeList">{{item.name}}</li>
                            </ul>
                        </div>
                        <div class="block clearfix">
                            <div class="left_info">面单：</div>
                            <ul>
                                <li @click="expressSheetId = item.id,getExpressFree(item.id)" :class="expressSheetId == item.id ? 'active':''" v-for="item in expressSheetList">{{item.shipperName}}({{expressSheetNameList[item.expressStatus]}})</li>
                            </ul>
                        </div>
                        <div class="block clearfix">
                            <div class="left_info">排序：</div>
                            <ul>
                                <li @click="formData.sort = '',search()" :class="formData.sort=='' ? 'active':''">默认排序</li>
                                <li @click="formData.sort = 1,search()" :class="formData.sort==1 ? 'active':''">价格最低</li>
                                <li @click="formData.sort = 3,search()" :class="formData.sort==3 ? 'active':''">销量最高</li>
                                <li @click="formData.sort = 4,search()" :class="formData.sort==4 ? 'active':''">重量最重</li>
                                <li @click="formData.sort = 5,search()" :class="formData.sort==5 ? 'active':''">最新发布</li>
                            </ul>
                        </div>
                    </div>
                    <div class="goods_list">
                        <el-scrollbar style="height:100%">
                            <div class="item" v-for="item in goodsList" @click="addCart(item)">
                                <dl>
                                    <dt>
                                        <img width="150px" height="150px" :src="item.cover | fullPath" alt="">
                                    </dt>
                                    <dd class="title">
                                        {{item.name}}
                                    </dd>
                                    <dd class="other">
                                        <span class="price">￥{{(item.cost+item.profit).toFixed(2)}}</span>
                                        <span class="wei">{{item.weight}} kg/件</span>
                                    </dd>
                                    <dd class="num">库存：{{item.repertory}}件</dd>
                                    <dd class="btn">加入购物车</dd>
                                </dl>
                            </div>
                        </el-scrollbar>
                    </div>
                    <div class="home_fy_block  wrap">
                        <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page.sync="formData.pageNo"
                        :page-size="100"
                        background=""
                        layout="prev, pager, next,jumper,total"
                        :total="total">
                        </el-pagination>
                    </div>
                </div>
                <div class="right_item">
                    <div class="bk_item">
                        <span>商品</span>
                        <span>数量/重量</span>
                        <span>价格</span>
                        <span>操作</span>
                    </div>
                    <div class="item_list">
                        <el-scrollbar v-if="shopCart.id">
                            <div class="item clearfix">
                                <div class="title">{{shopCart.name}}</div>
                                <dl class="clearfix">
                                    <dt>
                                        <img width="50px" height="50px" :src="shopCart.cover | fullPath" alt="">
                                    </dt>
                                    <dd>
                                        <div class="jia" @click="subtractNum">-</div>
                                        <div class="num">
                                            <input min="1" @input="inputNum" type="number" v-model="shopCart.num">
                                        </div>
                                        <div class="jia" @click="addNum">+</div>
                                        <div class="price">￥{{Number(shopCart.cost+shopCart.profit).toFixed(2)}}</div>
                                        <div class="del" @click="clearShopCart">x</div>
                                    </dd>
                                    <dd>
                                        <p>{{shopCart.weight}}kg</p>
                                        {{shopCart.repertory}}库存
                                    </dd>
                                </dl>
                                <div class="storage">
                                    <span>{{shopCart.store}}</span>
                                </div>
                            </div>
                        </el-scrollbar>
                        <div class="home_empty_list" v-else>
                            <i class="el-icon-warning-outline"></i>
                            暂无获取到相对应数据！
                        </div>
                    </div>
                    <div class="item_b">
                        <div class="b_title">下单后每个收件人将收到以上产品</div>
                        <div class="price">
                            <span>￥{{goodsPrice}}</span>
                            商品价格
                        </div>
                        <div class="weight">
                            <span>￥{{expressPrice}}</span>
                            快递运费
                        </div>
                        <div class="total">
                            <span>￥{{sumPrice}}</span>
                            总计：
                        </div>
                    </div>

                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="dialogVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="addGoods">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 新增店铺 -->
        <el-dialog
            title="新增店铺"
            :visible.sync="dialogVisible1"
            @close="closeDialog1"
            width="30%">
            <el-form ref="form" :model="formData1" label-width="80px" size="medium">
				<el-form-item label="店铺类型">
					<el-select v-model="formData1.type" placeholder="请选择店铺类型">
					<el-option label="淘宝/天猫" value="2"></el-option>
					<el-option label="拼多多" value="4"></el-option>
                    <el-option label="抖音" value="5"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="店铺名称">
					<el-input v-model="formData1.name"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="addHouse">确定</el-button>
					<el-button @click="dialogVisible1=false">取消</el-button>
				</el-form-item>
			</el-form>
        </el-dialog>
        <el-dialog
			title="查看店铺列表(请购买服务后再进行授权)"
			:visible.sync="dialogVisible2"
			width="600">
			<el-table
			:data="houseList"
			style="width: 100%">
			<el-table-column
				align="center"
				label="类型">
				<template slot-scope="scope">
			        <p v-if="scope.row.type==2">淘宝/天猫</p>
                    <p v-if="scope.row.type==4">拼多多</p>
                    <p v-if="scope.row.type==5">抖音</p>
			    </template>
			</el-table-column>
			<el-table-column
				prop="name"
				align="center"
				label="店铺名">
			</el-table-column>
			<el-table-column
				align="center"
				label="授权状态">
				<template slot-scope="scope">
			        <p v-if="scope.row.status==1">已授权</p>
                    <p v-if="scope.row.status==0">未授权</p>
			    </template>
			</el-table-column>
			<el-table-column
				label="操作"
				width="300"
				>
				<template slot-scope="scope">
			        <el-button size="small" @click="buyServices(scope.row.type)">购买服务</el-button>
					<el-button size="small" v-if="scope.row.status==0" @click="autoDeliver(scope.row.type,scope.row.id)">授权</el-button>
					<el-button size="small" v-if="scope.row.status==1" @click="autoDeliver(scope.row.type,scope.row.id)">重新授权</el-button>
					<el-button size="small" type="danger" @click="deleteHouse(scope.row.id)">删除</el-button>
			    </template>
			</el-table-column>
			</el-table>
		</el-dialog>
        <el-dialog
            title="无法筛选怎么办？"
            :visible.sync="dialogVisible3">
            <img src="@/assets/img/dont.png" width="760px" style="margin: 0px auto;" alt="">
        </el-dialog>
        <el-dialog
            title="编辑收件人地址"
            width="600px"
            center
            :visible.sync="showTool">
            <div class="input_put_block">
                <div class="put_item">
                    <div class="item">
                        <span>订单编号:</span>
                        <input type="text" v-model="editTool.orderNum" placeholder="订单编号">
                    </div>
                    <div class="item">
                        <span>姓名:</span>
                        <input type="text" v-model="editTool.consignee" placeholder="姓名">
                    </div>
                    <div class="item">
                        <span>电话:</span>
                        <input type="text" v-model="editTool.consigneePhone" placeholder="电话">
                    </div>
                    <div class="item">
                        <span>省:</span>
                        <input type="text" v-model="editTool.province" placeholder="省">
                    </div>
                    <div class="item">
                        <span>市:</span>
                        <input type="text" v-model="editTool.city" placeholder="市">
                    </div>
                    <div class="item">
                        <span>区:</span>
                        <input type="text" v-model="editTool.county" placeholder="区">
                    </div>
                    <div class="item">
                        <span>详细地址:</span>
                        <input type="text" v-model="editTool.address" placeholder="详细地址">
                    </div>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" type="danger" @click="checkEdit">确认修改</el-button>
                <el-button size="small" type="defauil" @click="showTool = false">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      flist1: [],
      flist2: [],
      houseList: [],
      houseList1: [],
      taskList4: [],
      typeList: [],
      storeList: [],
      goodsList: [],
      packageNow4: [],
      expressSheetNameList: [
        "其他面单",
        "通用面单",
        "菜鸟面单",
        "拼多多面单",
        "京东面单",
      ],
      expressSheetList: [],
      shopCart: {},
      shopCart1: {
        name: "",
      },
      formData1: {
        type: "",
        name: "",
      },
      formData: {
        pageNo: 1,
        pageSize: 20,
        keyWord: "",
        sort: "",
        storeId: "",
        type: "",
      },
      editTool: {
        orderNum: "",
        consignee: "",
        consigneeAddr: "",
        consigneePhone: "",
        address: "",
        city: "",
        county: "",
        province: "",
        index: 0,
      },
      pddObj: {
        endTime: "",
        startTime: "",
      },
      douyinObj: {
        busShopId: 0,
        pageSize: 100,
        pageNo: 0,
        createTimeStart: "",
        createTimeEnd: "",
      },
      timeArr: [],
      pddLoading: false,
      pddClickFlag: false,
      pddRadio: "1",
      stringRule: "",
      stringRule1: "",
      expressSheetId: "",
      houseId: "",
      remark: "",
      recipientListStr: "",
      expressFreeInfo: {},
      dialogVisible: false,
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: false,
      showTool: false,
      showGoodsList: false,
      platform: 2,
      model: 1,
      way: 1,
      total: 0,
      goodsPrice: 0, //商品总价
      expressPrice: 0, //邮费
      sumPrice: 0, //总价 = 商品总价 + 邮费
      file: "",
      file2: "",
      chajian: "",
      flag: false,
      submitFlag: false,
      storeList2: [],
      remark2: "",
      goodPrice: "",
      samllGoodPrice: "",
      largeGoodPrice: "",
      videoUrl:
        "/files/video/%E6%B7%98%E5%AE%9D%E6%8F%92%E4%BB%B6%E5%8F%91%E8%B4%A7%E6%95%99%E7%A8%8B.mp4",
      douyinLoading: false,
      phones: "",
    };
  },
  created() {
    var host = window.location.host;
    this.phones = localStorage.getItem("phone");
    if (host == "www.fabwang.com") {
      this.$alert(
        "请使用新域名 www.youmaidan.com，届时老域名可能会存在无法访问的情况，使用新域名不会有此弹窗，望周知！！建议商家们可以加下客服QQ3007671066，个性签名会更新最新的域名地址",
        "访问新域名",
        {
          confirmButtonText: "确定",
          type: "warning",
          center: true,
        }
      )
        .then(() => {
          window.location.href = "http://www.youmaidan.com/business/";
        })
        .catch(() => {
          window.location.href = "http://www.youmaidan.com/business/";
        });
      return;
    }

    this.getHouseList();
    setTimeout(() => {
      let arr = localStorage.getItem("orderList");
      if (arr) {
        let str = "";
        arr = JSON.parse(arr);
        arr.forEach((res, index) => {
          if (index < arr.length - 1) {
            str +=
              res.order_id.replace(/\n/g, "").replace(/ /g, "") +
              "," +
              res.address +
              "\n";
          } else {
            str +=
              res.order_id.replace(/\n/g, "").replace(/ /g, "") +
              "," +
              res.address;
          }
        });
        this.recipientListStr = str;
        localStorage.removeItem("orderList");
      }
    }, 1000);
    var host = window.location.host;
    if (host.indexOf("kxj.axlix.com") != -1) {
      this.chajian = "http://file.axlix.com/kxj/KxjAxlixCom.zip";
      this.videoUrl = "http://file.axlix.com/kxj/tbchajianfahuo.mp4";
    } else {
      var a = host.split(".");
      if (a.length == 2) {
        this.chajian =
          window.location.protocol +
          "//" +
          host +
          "/files/chajian/www" +
          a[0] +
          ".rar";
      } else {
        this.chajian =
          window.location.protocol +
          "//" +
          host +
          "/files/chajian/" +
          a[0] +
          a[1] +
          ".rar";
      }
    }

    var goodsId = this.$route.query.goodsId;
    console.log(goodsId);
    if (goodsId) {
      this.getGoodsInfo(goodsId);
    } else {
      if (this.$store.state.goodsInfo.name) {
        this.showDialog();
      }
    }
    this.getStoreList2();
  },
  mounted() {},
  watch: {
    shopCart: {
      handler(newValue, oldValue) {
        this.countPrice();
      },
      deep: true,
    },
    platform() {
      this.getHouseList2();
    },
  },
  computed: {
    sum() {
      return this.taskList4.length * this.sumPrice;
    },
    checkState() {
      if (this.taskList4.length > 0 && this.shopCart1.name) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    toAuth() {
      if (this.houseId == "") {
        this.$message.error("请选择店铺列表");
      } else {
        this.$request.post({
          url: "/bus/shop/getAppId",
          params: {
            id: this.houseId,
          },
          success: (res) => {
            window.open(res.url);
          },
        });
      }
    },
    getHtmlContent(html) {
      let htmles = html.split(">", 2);
      let htmless = htmles[1].split("<", 1);
      return htmless[0];
    },
    getDouyinOrder() {
      if (this.flag) return;
      this.flag = true;
      const loading = this.$loading();
      if (this.houseId == "") {
        this.$message.error("请选择店铺列表");
        this.flag = false;
        loading.close();
        return;
      }
      if (this.douyinObj.createTimeStart == "") {
        this.$message.error("请选择时间");
        this.flag = false;
        loading.close();
        return;
      }
      let time1 = new Date(this.timeArr[0]).getTime();
      let time2 = new Date(this.timeArr[1]).getTime();
      if (time2 - time1 > 86400000) {
        this.$message.error("时间差不能超过24小时");
        this.flag = false;
        loading.close();
        return;
      }
      let leftPrice = this.samllGoodPrice == "" ? 0 : this.samllGoodPrice;
      let rightPrice = this.largeGoodPrice == "" ? 999999 : this.largeGoodPrice;
      if (this.samllGoodPrice != "" || this.largeGoodPrice != "") {
        this.goodPrice = leftPrice + "-" + rightPrice;
      }
      this.douyinLoading = true;
      this.$request.post({
        url: "/bus/shop/dyGetOrderList",
        params: {
          busShopId: this.houseId,
          createTimeStart: this.douyinObj.createTimeStart,
          createTimeEnd: this.douyinObj.createTimeEnd,
          pageSize: this.douyinObj.pageSize,
          pageNo: this.douyinObj.pageNo,
          remark2: this.remark2,
          goodPrice: this.goodPrice,
        },
        success: (res) => {
          if (this.pddClickFlag) {
            this.taskList4 = [];
          }
          this.pddClickFlag = true;
          res.forEach((res) => {
            let obj = {
              commonId: "",
              html: `<span style="color:green">信息正确</span>`,
              conlist: [
                {
                  addr: [],
                  consignee: res.name,
                  consigneeAddr:
                    res.province + res.city + res.county + res.address,
                  consigneePhone:
                    res.phone.indexOf("-") != -1
                      ? res.phone.split("-")[1]
                      : res.phone,
                  orderNum: res.orderNo,
                  addrStr: res.province + res.city + res.county + res.address,
                  address: res.address,
                  city: res.city,
                  county: res.county,
                  province: res.province,
                  encryptAddress: res.encryptAddress,
                  encryptName: res.encryptName,
                  encryptPhone: res.encryptPhone,
                  tradeName: res.tradeName,
                  remark: res.remark,
                  goodsPrice: res.goodsPrice,
                },
              ],
              goodsIdlist: "",
              goodsName: "",
              goodsNumlist: "",
              price: "",
              show: false,
              totalNum: 1,
            };
            this.taskList4.push(obj);
            //consloe.log(this.taskList4);
          });
        },
        finally: (res) => {
          this.douyinLoading = false;
          this.flag = false;
          loading.close();
        },
      });
    },
    timeChange2(val) {
      if (val) {
        this.douyinObj.createTimeStart = this.$time.getDate(val[0]);
        this.douyinObj.createTimeEnd = this.$time.getDate(val[1]);
        //consloe.log(this.$time.getDate(val[0]));
      } else {
        this.douyinObj.createTimeStart = "";
        this.douyinObj.createTimeEnd = "";
      }
    },
    openUrl(url) {
      window.open(url);
    },
    getGoodsInfo(goodsId) {
      this.$request.post({
        url: "/ReportApi/business/newdetail",
        params: { goodsId: goodsId },
        success: (res) => {
          console.log(res)
          this.$store.commit("getGoodsInfo", res);
          this.showDialog();
        },
        finally: () => {},
      });
    },
    pddRadioChange(vale) {
      this.stringRule1 = "";
      this.pddObj = {
        endTime: "",
        startTime: "",
      };
      this.douyinObj = {
        createTimeStart: "",
        createTimeEnd: "",
        pageNo: 0,
        pageSize: 100,
      };
      this.timeArr = [];
    },
    changPlatform(num) {
      this.platform = num;
      this.houseId = "";
      if (num != 4 && this.model == 4) {
        this.model = 1;
      }
    },
    getPDDOrder() {
      if (this.flag) return;
      this.flag = true;
      const loading = this.$loading();
      if (this.houseId == "") {
        this.$message.error("请选择店铺列表");
        this.flag = false;
        loading.close();
        return;
      }
      if (this.pddObj.startTime == "") {
        this.$message.error("请选择时间");
        this.flag = false;
        loading.close();
        return;
      }
      let time1 = new Date(this.timeArr[0]).getTime();
      let time2 = new Date(this.timeArr[1]).getTime();
      if (time2 - time1 > 86400000) {
        this.$message.error("时间差不能超过24小时");
        this.flag = false;
        loading.close();
        return;
      }
      let leftPrice = this.samllGoodPrice == "" ? 0 : this.samllGoodPrice;
      let rightPrice = this.largeGoodPrice == "" ? 999999 : this.largeGoodPrice;
      if (this.samllGoodPrice != "" || this.largeGoodPrice != "") {
        this.goodPrice = leftPrice + "-" + rightPrice;
      }
      this.pddLoading = true;
      this.$request.post({
        url: "/bus/shop/getOrderList",
        params: {
          id: this.houseId,
          startTime: this.pddObj.startTime,
          endTime: this.pddObj.endTime,
          remark2: this.remark2,
          goodPrice: this.goodPrice,
        },
        success: (res) => {
          if (this.pddClickFlag) {
            this.taskList4 = [];
          }
          this.pddClickFlag = true;
          res.forEach((res) => {
            let phone, html, status;
            if (res.phone) {
              phone =
                res.phone.indexOf("-") != -1
                  ? res.phone.split("-")[1]
                  : res.phone;
              html = `<span style="color:green">信息正确</span>`;
              status = 1;
            } else {
              res.phone = "";
              html = `<span style="color:red">电话有误</span>`;
              status = 0;
            }
            let obj = {
              commonId: "",
              html,
              status,
              conlist: [
                {
                  addr: [],
                  consignee: res.name,
                  consigneeAddr:
                    res.province + res.city + res.county + res.address,
                  consigneePhone: phone,
                  orderNum: res.orderNo,
                  addrStr: res.province + res.city + res.county + res.address,
                  address: res.address,
                  city: res.city,
                  county: res.county,
                  province: res.province,
                  encryptAddress: res.encryptAddress,
                  encryptName: res.encryptName,
                  encryptPhone: res.encryptPhone,
                  tradeName: res.tradeName,
                  remark: res.remark,
                  goodsPrice: res.goodsPrice,
                },
              ],
              goodsIdlist: "",
              goodsName: "",
              goodsNumlist: "",
              price: "",
              show: false,
              totalNum: 1,
            };
            this.taskList4.push(obj);
          });
        },
        finally: (res) => {
          this.pddLoading = false;
          this.flag = false;
          loading.close();
        },
      });
    },
    getTBOrder() {
      if (this.flag) return;
      this.flag = true;
      const loading = this.$loading();
      if (this.houseId == "") {
        this.$message.error("请选择店铺列表");
        this.flag = false;
        loading.close();
        return;
      }
      if (this.pddObj.startTime == "") {
        this.$message.error("请选择时间");
        this.flag = false;
        loading.close();
        return;
      }
      let time1 = new Date(this.timeArr[0]).getTime();
      let time2 = new Date(this.timeArr[1]).getTime();
      let leftPrice = this.samllGoodPrice == "" ? 0 : this.samllGoodPrice;
      let rightPrice = this.largeGoodPrice == "" ? 999999 : this.largeGoodPrice;
      if (this.samllGoodPrice != "" || this.largeGoodPrice != "") {
        this.goodPrice = leftPrice + "-" + rightPrice;
      }
      this.pddLoading = true;
      this.$request.post({
        url: "/bus/shop/getTbOrderList",
        params: {
          id: this.houseId,
          startTime: this.pddObj.startTime,
          endTime: this.pddObj.endTime,
          sellerMemo: this.remark2,
          goodPrice: this.goodPrice,
        },
        closeErrInfo: true,
        success: (res) => {
          if (this.pddClickFlag) {
            this.taskList4 = [];
          }
          this.pddClickFlag = true;
          res.forEach((res) => {
            let phone, html, status;
            if (res.phone) {
              phone =
                res.phone.indexOf("-") != -1
                  ? res.phone.split("-")[1]
                  : res.phone;
              html = `<span style="color:green">信息正确</span>`;
              status = 1;
            } else {
              res.phone = "";
              html = `<span style="color:red">电话有误</span>`;
              status = 0;
            }
            let obj = {
              commonId: "",
              html,
              status,
              conlist: [
                {
                  addr: [],
                  consignee: res.name,
                  consigneeAddr:
                    res.province + res.city + res.county + res.address,
                  consigneePhone: phone,
                  orderNum: res.orderNo,
                  addrStr: res.province + res.city + res.county + res.address,
                  address: res.address,
                  city: res.city,
                  county: res.county,
                  province: res.province,
                  tradeName: res.tradeName,
                  goodsPrice: res.goodsPrice,
                  sellerMemo: res.sellerMemo,
                  remark: res.remark,
                  encryptName: res.encryptName,
                },
              ],
              goodsIdlist: "",
              goodsName: "",
              goodsNumlist: "",
              price: "",
              show: false,
              totalNum: 1,
            };
            this.taskList4.push(obj);
          });
        },
        error: (res) => {
          if (res.indexOf("打单软件服务到期") != -1) {
            var str = "打单软件服务已到期，是否前往续费？";
            this.$confirm(str, "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
              center: true,
            })
              .then(() => {
                window.open(
                  "https://fuwu.taobao.com/ser/detail.htm?spm=a1z13.pc_search_result.1234-fwlb.5.78ec5acaZRnAnk&service_code=ts-21954&tracelog=search&from_key=%E6%98%93%E9%80%9A+%E6%89%93%E5%8D%B0"
                );
              })
              .catch(() => {});
          } else {
            this.$message.error(res);
          }
        },
        finally: (res) => {
          this.pddLoading = false;
          this.flag = false;
          loading.close();
        },
      });
    },
    timeChange(val) {
      if (val) {
        this.pddObj.startTime = this.$time.getDate(val[0]);
        this.pddObj.endTime = this.$time.getDate(val[1]);
        this.douyinObj.createTimeStart = this.$time.getDate(val[0]);
        this.douyinObj.createTimeEnd = this.$time.getDate(val[1]);
      } else {
        this.pddObj.startTime = "";
        this.pddObj.endTime = "";
        this.douyinObj.createTimeStart = "";
        this.douyinObj.createTimeEnd = "";
      }
    },
    uploadFile2(e) {
      if (this.file2 == "") {
        this.$message.warning("请先上传文件");
        return;
      }
      if (this.stringRule == "") {
        this.$message.warning("请根据您选择的筛选类型填写订单号或者用户名");
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      let strArr = [];
      this.stringRule.split("\n").forEach((item) => {
        strArr.push(item);
      });
      let str = strArr.join(",");
      let shopId;
      if (
        this.houseId != "" &&
        (this.platform == 2 || this.platform == 4 || this.platform == 5)
      ) {
        shopId = this.houseId;
      } else {
        shopId = undefined;
      }
      let formData = new FormData();
      formData.append("excelFile", this.file2);
      formData.append("belongTerrace", this.platform);
      formData.append("string", str);
      formData.append("type", this.way);
      console.log(formData)
      this.$axios.post("/bus/order/filterImport", formData).then((res) => {
        if (res.data.code == "0") {
          loading.close();
          if (res.data.result.length === 0) {
            this.$message.error("没有匹配的数据");
            return;
          }
          this.$message.success("上传成功");
          let str = {
            list: res.data.result,
            platform: this.platform,
            importType: this.model,
            shopId,
          };
          str = JSON.stringify(str);
          sessionStorage.setItem("real_list_data", str);
          this.$router.push("/member/check_order");
        } else {
          loading.close();
          this.$message.error(
            "您的表格与选择的平台类型不符合或未匹配到对应参数，请确认后重新上传。"
          );
        }
      });
    },
    handleExceed(e) {
      this.$message.error(
        "最多上传一个文件，若想上传另外的文件，请先删除原来的文件"
      );
    },
    uploadFile() {
      if (this.file == "") {
        this.$message.warning("请先上传文件");
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      let formData = new FormData();
      formData.append("excelFile", this.file);
      formData.append("belongTerrace", this.platform);
      
      this.$axios.post("/bus/order/templateImport", formData).then((res) => {
        if (res.data.code == "0") {
         
          loading.close();
          this.$message.success("上传成功");
          let shopId;
          if (
            this.houseId != "" &&
            (this.platform == 2 || this.platform == 4 || this.platform == 5)
          ) {
            shopId = this.houseId;
          } else {
            shopId = undefined;
          }
          let str = {
            list: res.data.result,
            platform: this.platform,
            importType: this.model,
            shopId,
          };
          str = JSON.stringify(str);
          sessionStorage.setItem("real_list_data", str);
          this.$router.push("/member/check_order");
        } else {
          loading.close();
          this.$message.error(
            "您的表格与选择的平台类型不符合或未匹配到对应参数，请确认后重新上传。"
          );
        }
      });
    },
    getFile(e) {
      this.file = e.file;
    },
    getFile2(e) {
      this.file2 = e.file;
    },
    deleteHouse(id) {
      this.$confirm("确定删除该店铺?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$request
            .post({
              url: "/ReportApi/business/shopdetele",
              params: {
                ids:id,
              },
              success: (res) => {
                this.$message.success("删除成功");
                this.getHouseList();
              },
            })
            .catch(function (error) {
              //consloe.log(error);
            });
        })
        .catch(() => {});
    },
    autoDeliver(type, id) {
      this.$request.post({
        url: "/bus/shop/getAppId",
        params: {
          id,
        },
        success: (res) => {
          if (type == 2) {
            //window.open('http://acs.agiso.com/authorize.aspx?appId='+res.appId+'&state='+res.state);
            //consloe.log(res.url);
            window.open(res.url);
          } else {
            // window.open('http://acsPdd.agiso.com/#/authorize?appId='+res.appId+'&state='+res.state);
            //consloe.log(res.url);
            window.open(res.url);
          }
        },
      });
    },
    buyServices(type) {
      if (type == 2) {
        //window.open('https://fuwu.taobao.com/ser/detail.htm?service_code=FW_GOODS-1978346&tracelog=search&from_key=%E6%98%93%E5%BA%97%E9%95%BF')
        window.open(
          "https://fuwu.taobao.com/ser/detail.htm?spm=a1z13.pc_search_result.1234-fwlb.5.78ec5acaZRnAnk&service_code=ts-21954&tracelog=search&from_key=%E6%98%93%E9%80%9A+%E6%89%93%E5%8D%B0"
        );
      } else if (type == 5) {
        window.open(
          "https://fuwu.jinritemai.com/detail?from=fuwu_market_tab&service_id=248"
        );
      } else {
        // window.open('https://fuwu.pinduoduo.com/service-market/service-detail?detailId=434')
        window.open(
          "https://fuwu.pinduoduo.com/service-market/service-detail?detailId=15141"
        );
      }
    },
    closeDialog1() {
      this.formData1 = {
        name: "",
        type: "",
      };
    },
    closeDialog2() {
      this.$store.commit("getGoodsInfo", {});
      this.formData.keyWord = "";
    },
    addHouse() {
      if (this.formData1.name == "") {
        this.$message.warning("请填写店铺名称");
        return;
      }
      if (this.formData1.type == "") {
        this.$message.warning("请选择店铺类型");
        return;
      }
      this.$request.post({
        url: "/ReportApi/business/shopadd",
        params: {
          type: this.formData1.type,
          name: this.formData1.name,
        },
        success: (res) => {
          this.$message.success("添加成功");
          this.dialogVisible1 = false;
          this.getHouseList();
          this.dialogVisible2 = true;
        },
      });
    },
    getHouseList2() {
      this.houseList1 = [];
      this.houseList.forEach((res) => {
        if (res.type == this.platform) {
          this.houseList1.push(res);
        }
      });
    },
    getHouseList() {
      this.$request.post({
        url: "/ReportApi/business/shopall",
        success: (res) => {
          this.houseList = res;
          //consloe.log(res);
          //consloe.log(this.platform);
          res.forEach((res) => {
            if (res.type == this.platform) {
              this.houseList1.push(res);
            }
          });
        },
      });
    },
    checkAreaSubmit() {
      if (!this.formData.storeId) {
        return;
      }
      if (this.model != 5) {
        if (this.submitFlag) return;
        this.submitFlag = true;
        const loading = this.$loading();
        this.$request.post({
          url: "/ReportApi/business/sysstoparealist",
          params: {
            storeId: this.formData.storeId,
          },
          success: (res) => {
            for (let i = 0; i < res.length; i++) {
            delete res[i].id;
            delete res[i].status;

            res[i].areaType=res[i].area_type
            delete res[i].area_type
             res[i].cityName=res[i].area_name
            delete res[i].area_name
             res[i].createTime=res[i].create_time
            delete res[i].create_time
             res[i].expAreaName=res[i].exp_area_name
            delete res[i].exp_area_name
             res[i].provinceName=res[i].province_name
            delete res[i].province_name
             res[i].storeId=res[i].store_id
            delete res[i].store_id
          }
           
            let htm = '<span style="color:red">地区快递停发</span>';
            this.taskList4.forEach((v1, index, array) => {
              if (
                res.length == 0 &&
                this.taskList4[index].html.indexOf(htm) != -1
              ) {
                this.taskList4[index].html =
                  '<span style="color:#10b573">信息正确</span>';
                this.taskList4[index].status = 1;
              } else {
                res.some((v2) => {
                  if (
                    v2.areaType == 1 &&
                    v2.provinceName.indexOf(v1.conlist[0].province) != -1
                  ) {
                    this.taskList4[index].html = htm;
                    this.taskList4[index].status = 0;
                    return true;
                  } else if (
                    v2.areaType == 2 &&
                    v2.provinceName.indexOf(v1.conlist[0].province) != -1 &&
                    v2.cityName.indexOf(v1.conlist[0].city) != -1
                  ) {
                    this.taskList4[index].html = htm;
                    this.taskList4[index].status = 0;
                    return true;
                  } else if (
                    v2.areaType == 3 &&
                    v2.provinceName.indexOf(v1.conlist[0].province) != -1 &&
                    v2.cityName.indexOf(v1.conlist[0].city) != -1 &&
                    v2.expAreaName.indexOf(v1.conlist[0].county) != -1
                  ) {
                    this.taskList4[index].html = htm;
                    this.taskList4[index].status = 0;
                    return true;
                  }
                });
              }
            });
            this.submitFlag = false;
            loading.close();
            this.submit();
          },
          error: (res) => {
            this.submitFlag = false;
            loading.close();
          },
        });
      } else {
        this.submit();
      }
    },
    deleteDataAkey() {
      this.$confirm("确定删除错误订单数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        for (var i = 0; i < this.taskList4.length; i++) {
          if (this.taskList4[i].status == 0) {
            this.taskList4.splice(i, 1);
            i--;
          }
        }
      });
    },
    sortDataAkey() {
      this.$confirm("确定进行有误排序?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        for (var i = 0; i < this.taskList4.length; i++) {
          if (this.taskList4[i].status == 0) {
            this.taskList4.unshift(this.taskList4.splice(i, 1)[0]);
          }
        }
      });
    },
    submit() {
      if (this.submitFlag) return;
      this.submitFlag = true;
      const loading = this.$loading();
      if (this.taskList4.length < 1) {
        this.submitFlag = false;
        loading.close();
        this.$message.warning("请填写收货人信息");
        return;
      }
      if (this.shopCart1.name == "") {
        this.submitFlag = false;
        loading.close();
        this.$message.warning("请选择商品");
        return;
      }
      let item = this.taskList4.find((res) => {
        if (res.status === 0) {
          return true;
        }
      });
      if (item) {
        this.submitFlag = false;
        loading.close();
        this.$message.warning("收货人信息有误");
        return;
      }
      let remark,
        shopId,
        recipientInfo = [],
        recipientInfos = []
      if (this.remark != "") {
        remark = this.remark;
      } else {
        remark = undefined;
      }
      if (
        this.houseId != "" &&
        (this.platform == 2 || this.platform == 4 || this.platform == 5)
      ) {
        shopId = this.houseId;
      } else {
        shopId = undefined;
      }
      let nameIndex = "";
      if (this.model == 4) {
        this.taskList4.forEach((res, index) => {
          let obj = {
            orderNo: res.conlist[0].orderNum,
            name: res.conlist[0].consignee,
            phone: res.conlist[0].consigneePhone,
            province: res.conlist[0].province,
            city: res.conlist[0].city,
            county: res.conlist[0].county,
            address: res.conlist[0].address,
            encryptAddress: res.conlist[0].encryptAddress,
            encryptName: res.conlist[0].encryptName,
            encryptPhone: res.conlist[0].encryptPhone,
            tradeName: res.conlist[0].tradeName,
          };
          recipientInfo.push(obj);

          if (res.conlist[0].consignee.length > 15) {
            if (nameIndex) {
              nameIndex = nameIndex + "," + index;
            } else {
              nameIndex = index;
            }
          }
        });
      } else {
        this.taskList4.forEach((res, index) => {
          let obj = {
            orderNo: res.conlist[0].orderNum,
            name: res.conlist[0].consignee,
            phone: res.conlist[0].consigneePhone,
            province: res.conlist[0].province,
            city: res.conlist[0].city,
            county: res.conlist[0].county,
            address: res.conlist[0].address,
            remark: res.conlist[0].remark,
            encryptName: res.conlist[0].encryptName,
          };
          recipientInfo.push(obj);

          if (res.conlist[0].consignee.length > 15) {
            if (nameIndex) {
              nameIndex = nameIndex + "," + index;
            } else {
              nameIndex = index;
            }
          }
        });
      }
      if (nameIndex) {
        this.submitFlag = false;
        loading.close();
        this.$message.warning("第" + nameIndex + "行收货人姓名不能超过15个字");
        return;
      }
       recipientInfos = recipientInfo;
      recipientInfo= JSON.stringify(recipientInfo)
     
      let item1 = this.expressSheetList.find((res, index) => {
        if (res.id == this.expressSheetId) {
          return true;
        }
      });
      let expressSheetName = this.expressSheetNameList[item1.expressStatus];
      if (this.expressSheetNameList.length != 1 && item1.expressStatus != 1) {
        if (this.platform == 2 && item1.expressStatus != 2) {
          this.submitFlag = false;
          loading.close();
          this.$message.warning("当前平台类型为淘宝/天猫，请选择菜鸟面单");
          return;
        }
        if (this.platform == 4 && item1.expressStatus != 3) {
          this.submitFlag = false;
          loading.close();
          this.$message.warning("当前平台类型为拼多多，请选择拼多多面单");
          return;
        }
        if (this.platform == 3 && item1.expressStatus == 2) {
          this.submitFlag = false;
          loading.close();
          this.$message.warning("当前平台类型为京东，请不要选择菜鸟面单");
          return;
        }
        if (
          this.platform == 5 &&
          item1.expressStatus != 2 &&
          item1.expressStatus != 1
        ) {
          this.submitFlag = false;
          loading.close();
          this.$message.warning("当前平台类型为抖音，请选择菜鸟面单或通用面单");
          return;
        }
      }

      let str = "";
      if (
        this.platform == 2 &&
        item1.expressStatus != 2 &&
        item1.expressStatus != 1
      ) {
        str = `当前的订单类型为淘宝/天猫，选择的面单类型为${expressSheetName}，订单类型与面单类型不一致，是否确认？`;
      }
      if (
        this.platform == 4 &&
        item1.expressStatus != 3 &&
        item1.expressStatus != 1
      ) {
        str = `当前的订单类型为拼多多，选择的面单类型为${expressSheetName}，订单类型与面单类型不一致，是否确认？`;
      }
      if (
        this.platform == 3 &&
        item1.expressStatus != 1 &&
        item1.expressStatus != 4
      ) {
        str = `当前的订单类型为京东，选择的面单类型为${expressSheetName}，订单类型与面单类型不一致，是否确认？`;
      }
      if (
        this.platform == 5 &&
        item1.expressStatus != 1 &&
        item1.expressStatus != 2
      ) {
        str = `当前的订单类型为抖音，选择的面单类型为${expressSheetName}，订单类型与面单类型不一致，是否确认？`;
      }
      str = str + "商品一经快递发出，无法退款，请须知，是否确认支付？";
      this.$confirm(str, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          var arr =recipientInfo.split(",");
          console.log(this.shopCart1)
              console.log(remark)
              console.log(arr.length)
                  console.log(typeof(recipientInfo));
          this.$request.post({
            url: "/ReportApi/business/sendCargo",
            params: {
              tenantId:localStorage.getItem('tenantInfoid'),
              belongTerrace: this.platform,
              expressSheetId: this.expressSheetId,
              goodsId: this.shopCart1.giId,
              goodsNum: this.shopCart1.num,
                 cost:this.shopCart1.cost,
                 profit : this.shopCart1.profit,
              importType: this.model,
              recipientInfo,
               recipientInfos,
              remark:this.remark,
              shopId:shopId,
              recipientInfolen: arr.length/7,
              userid:localStorage.getItem('ids'),
               level:this.$store.state.userInfo.level
               },
            success: (res) => {
              console.log(this.$store.state.userInfo.id)
              console.log(res)
              console.log(typeof(res))
              this.$message.success("支付成功");
              this.$router.push("/member/order");
              this.getUserInfo();
            },
            finally: () => {
              this.submitFlag = false;
              loading.close();
            },
          });
        })
        .catch(() => {
          this.submitFlag = false;
          loading.close();
          this.$message.defaultMsg("取消支付");
        });
    },
    getUserInfo() {
      this.$request.post({
        url: "/ReportApi/business/info",
        params: {
          phone: this.phones,
        },
        success: (res) => {
          var arr = {};
          delete res.first_recharge_time;
          delete res.openid;
          delete res.parent_id;
          delete res.password;
          delete res.remark;

          delete res.total_amount;

          res.createTime = res.create_time;
          delete res.create_time;
          res.firstRechargeStatus = res.first_recharge_status;
          delete res.first_recharge_status;
          res.inviteCount = res.invite_count;
          delete res.invite_count;
          res.levelId = res.level_id;
          delete res.level_id;
          res.withdrawStatus = res.withdraw_status;
          delete res.withdraw_status;
          arr.info = res;
          if (res.levelId == 0) {
            arr.level = "普通会员";
          } else if (res.levelId == 1) {
            arr.level = "高级会员";
          } else {
            arr.level = "最高会员";
          }
          this.formData = {
            phone: arr.info.phone,
            wechat: arr.info.wechat,
            qq: arr.info.qq,
            email: arr.info.email,
          };
          this.$store.commit("getUserInfo", arr);
        },
      });
    },
    addGoods() {//修改
      if (this.shopCart.stopStatus == 1) {
        this.$alert(this.shopCart.stopReason, "此仓库暂停下单", {
          confirmButtonText: "确定",
          center: true,
          callback: (action) => {},
        });
      } else {
        this.dialogVisible = false;
        let str = JSON.stringify(this.shopCart);
        this.shopCart1 = JSON.parse(str);
      }
    },
    countPrice() {
      if (this.shopCart.num) {
        let weight = this.shopCart.weight * this.shopCart.num;
        let item = this.expressFreeInfo.list.find((res) => {
          if (weight > res.min_weight && weight <= res.max_weight) {
            return true;
          }
        });
        if (item) {
          this.expressPrice = item.price;
        } else {
          let lastFlag = this.expressFreeInfo.list.length - 1;
          let lastItem = this.expressFreeInfo.list[lastFlag];
          weight = weight - lastItem.max_weight;
          weight = Math.ceil(weight);
          this.expressPrice = (
            lastItem.price +
            weight * this.expressFreeInfo.risePrice
          ).toFixed(2);
        }
        let goodsPrice =
          (this.shopCart.cost + this.shopCart.profit) * this.shopCart.num;
        this.goodsPrice = goodsPrice.toFixed(2);
        let sumPrice = Number(this.expressPrice) + Number(this.goodsPrice);
        this.sumPrice = sumPrice.toFixed(2);
      } else {
        this.goodsPrice = 0;
        this.expressPrice = 0;
        this.sumPrice = 0;
      }
    },
    clearShopCart() {
      this.shopCart = {};
    },
    inputNum(e) {
      if (this.shopCart.num < 1) {
        this.shopCart.num = 1;
      }
    },
    subtractNum() {
      if (this.shopCart.num == 1) {
        this.$message.warning("购买数量不能低于1");
      } else {
        this.shopCart.num--;
      }
    },
    addNum() {
      if (this.shopCart.num == 1) {
        this.$message.warning("购买数量不能大于1");
      } else {
        this.shopCart.num++;
      }
    },
    handleCurrentChange() {},
    handleSizeChange() {},
    addCart(e) {
      if (this.expressFreeInfo.list.length == 0) {
        this.$message.warning("此面单没有快递费用");
        return;
      }
      let store = "";
      let stopStatus = 1;
      let stopReason = "";
      if (this.formData.storeId === "") {
        store = "全部仓储";
      } else {
        let temp = this.storeList.find((res) => {
          if (res.id == this.formData.storeId) {
            store = res.name;
            stopStatus = res.stopStatus;
            stopReason = res.stopReason;
            return true;
          }
        });
      }
      this.shopCart = Object.assign(
        { num: 1, store, stopStatus, stopReason },
        e
      );
    },
    search() {
      this.formData.pageNo = 1;
      this.getGoodsList();
    },
    showDialog() {
      let str = JSON.stringify(this.shopCart1);
      this.shopCart = JSON.parse(str);
      this.dialogVisible = true;
      if (this.formData.storeId === "") {
        this.getTypeList();
        this.getStoreList();
      }
      if (this.expressSheetList.length > 0) {
        if (
          this.platform == 2 ||
          this.platform == 4 ||
          this.platform == 3 ||
          this.platform == 5
        ) {
          for (let i = 0; i < this.expressSheetList.length; i++) {
            if (
              this.expressSheetList[i].expressStatus == 2 &&
              this.platform == 2
            ) {
              this.expressSheetId = this.expressSheetList[i].id;
              break;
            }
            if (
              this.expressSheetList[i].expressStatus == 3 &&
              this.platform == 4
            ) {
              this.expressSheetId = this.expressSheetList[i].id;
              break;
            }
            if (
              this.expressSheetList[i].expressStatus == 1 &&
              this.platform == 3
            ) {
              this.expressSheetId = this.expressSheetList[i].id;
              break;
            }
            if (
              this.expressSheetList[i].expressStatus == 2 &&
              this.platform == 5
            ) {
              this.expressSheetId = this.expressSheetList[i].id;
              break;
            }
          }
        }
      }
    },
    deleteData(index) {
      this.taskList4.splice(index, 1);
    },
    checkEdit() {
      const index = this.editTool.index;
      this.taskList4[index].conlist[0].orderNum = this.editTool.orderNum;
      this.taskList4[index].conlist[0].consignee = this.editTool.consignee;
      this.taskList4[index].conlist[0].consigneeAddr =
        this.editTool.consigneeAddr;
      this.taskList4[index].conlist[0].consigneePhone =
        this.editTool.consigneePhone;
      this.taskList4[index].conlist[0].province =
        this.editTool.province.replace(/\s+/g, "");
      this.taskList4[index].conlist[0].county = this.editTool.county.replace(
        /\s+/g,
        ""
      );
      this.taskList4[index].conlist[0].city = this.editTool.city.replace(
        /\s+/g,
        ""
      );
      this.taskList4[index].conlist[0].address = this.editTool.address;
      let address = [];
      address.push(
        this.taskList4[index].conlist[0].province +
          " " +
          this.taskList4[index].conlist[0].county +
          " " +
          this.taskList4[index].conlist[0].city +
          " " +
          this.taskList4[index].conlist[0].address
      );

      let html = "";
      if (
        this.taskList4[index].conlist[0].address &&
        this.taskList4[index].conlist[0].city &&
        this.taskList4[index].conlist[0].county &&
        this.taskList4[index].conlist[0].province
      ) {
        html = '<span style="color:green">信息正确</span>';
        this.taskList4[index].html = html;
        this.taskList4[index].status = 1;
      } else {
        html = '<span style="color:red">地址有误</span>';
        this.taskList4[index].html = html;
        this.taskList4[index].status = 0;
      }
      let phone = this.taskList4[index].conlist[0].consigneePhone;
      if (this.platform !== 4 && !this.$check.isPhone(phone)) {
        html = '<span style="color:red">电话有误</span>';
        this.taskList4[index].html = html;
        this.taskList4[index].status = 0;
      }
      this.$request.post({
        url: "/ReportApi/business/cutaddress",
        params: {
          belongTerrace: this.platform,
          receiveAddress: address[0],
        },
        success: (res) => {
          //consloe.log(res);
          var ress = {
            address: res[0],
            city: res[1],
            county: res[2],
            province: res[3],
            sensitiveStr: res[4],
          };
          //consloe.log(ress);
          var reas = [];
          reas.push(ress);
          //consloe.log(reas);
          reas.forEach((reas) => {
            if (reas.sensitiveStr) {
              html =
                '<span style="color:red">请修改地址中存在敏感词：' +
                reas.sensitiveStr +
                "</span>";
              this.taskList4[this.editTool.index].html = html;
              this.taskList4[this.editTool.index].status = 0;
            }
          });
        },
      });
      this.showTool = false;
      this.editTool = {
        orderNum: "",
        consignee: "",
        consigneeAddr: "",
        consigneePhone: "",
        index: 0,
      };
    },
    edit(data, index) {
      this.showTool = true;
      this.editTool.orderNum = data.conlist[0].orderNum;
      this.editTool.consignee = data.conlist[0].consignee;
      this.editTool.consigneeAddr = data.conlist[0].consigneeAddr;
      this.editTool.consigneePhone = data.conlist[0].consigneePhone;
      this.editTool.address = data.conlist[0].address;
      this.editTool.city = data.conlist[0].city;
      this.editTool.county = data.conlist[0].county;
      this.editTool.province = data.conlist[0].province;
      this.editTool.index = index;
    },
    //查看全部分类
    getTypeList() {
      this.$request.post({
        url: "/ReportApi/business/goodstypes",
        params: {},
        success: (res) => {
          for (let i = 0; i < res.length; i++) {
            res[i].createTime = res[i].create_time;
            delete res[i].create_time;
            res[i].otberId = res[i].other_id;
            delete res[i].other_id;
            res[i].otherType = res[i].other_type;
            delete res[i].other_type;
          }
          this.typeList = res;
          //consloe.log(res);
        },
      });
    },
    //查看全部仓库
    getStoreList2() {
      this.$request.post({
        url: "/ReportApi/business/getstore",
        success: (res) => {
          this.storeList2 = res;
          //consloe.log(this.storeList2);
        },
      });
    },
    //查看全部仓库
    getStoreList() {
      this.$request.post({
        url: "/ReportApi/business/getstore",
        success: (res) => {
          this.storeList = res;
          if (this.storeList.length > 0) {
            if (this.$store.state.goodsInfo.name) {
              this.formData.storeId = this.$store.state.goodsInfo.storeId;
            } else {
              this.formData.storeId = this.storeList[0].id;
            }
            this.getExpressSheet(this.formData.storeId);
            this.getGoodsList();
          }
        },
      });
    },
    //通过仓库id查面单信息
    getExpressSheet(storeId) {
      this.$request.post({
        url: "/ReportApi/business/expresssheet",
        params: {
          storeId: storeId,
        },
        success: (res) => {
          for (let i = 0; i < res.length; i++) {
            res[i].createTime = res[i].create_time;
            delete res[i].create_time;
            res[i].cityName = res[i].city_name;
            delete res[i].city_name;
            res[i].expAreaName = res[i].exp_area_name;
            delete res[i].exp_area_name;
            res[i].expressStatus = res[i].express_status;
            delete res[i].express_status;
            res[i].expressType = res[i].express_type;
            delete res[i].express_type;
            res[i].otherType = res[i].other_type;
            delete res[i].other_type;
            res[i].provinceName = res[i].province_name;
            delete res[i].province_name;
            res[i].shipperName = res[i].shipper_name;
            delete res[i].shipper_name;
            res[i].shipperCode = res[i].shipper_code;
            delete res[i].shipper_code;
            res[i].storeId = res[i].store_id;
            delete res[i].store_id;
            delete res[i].customer_name;
            delete res[i].customer_pwd;
            delete res[i].address;
            delete res[i].mobile;
            delete res[i].name;
            delete res[i].other_id;
            delete res[i].tel;
            delete res[i].user_id;
          }
          //consloe.log(res);
          this.clearShopCart();
          this.expressSheetList = res;
          if (this.expressSheetList.length > 0) {
            this.expressSheetId = this.expressSheetList[0].id;
            if (
              this.platform == 2 ||
              this.platform == 4 ||
              this.platform == 3 ||
              this.platform == 5
            ) {
              for (let i = 0; i < res.length; i++) {
                if (res[i].expressStatus == 2 && this.platform == 2) {
                  this.expressSheetId = res[i].id;
                  break;
                }
                if (res[i].expressStatus == 3 && this.platform == 4) {
                  this.expressSheetId = res[i].id;
                  break;
                }
                if (res[i].expressStatus == 1 && this.platform == 3) {
                  this.expressSheetId = res[i].id;
                  break;
                }
                if (res[i].expressStatus == 2 && this.platform == 5) {
                  this.expressSheetId = res[i].id;
                  break;
                }
              }
            }
            this.getExpressFree(this.expressSheetId);
          }
        },
      });
    },
    getExpressFree(expressSheetId) {
      this.$request.post({
        url: "/ReportApi/business/getexpressfree",
        params: {
          expressSheetId: expressSheetId,
          tenantId: this.$store.state.userInfo.info.tenant_id,
          level: this.$store.state.userInfo.level,
        },
        success: (res) => {
          console.log(expressSheetId);
          res.list[0].max_weight = Number(res.list[0].max_weight);
          res.list[0].cost = Number(res.list[0].cost);
          res.list[0].discount = Number(res.list[0].discount);
          res.list[0].profit = Number(res.list[0].profit);
          res.list[0].price =
            (res.list[0].cost + res.list[0].profit) * res.list[0].discount;

          console.log(res);

          this.expressFreeInfo = res;
          if (res.list.length == 0) {
            this.$message.warning("此面单没有快递费用");
            this.shopCart = {};
            this.shopCart1 = {
              name: "",
            };
          }
          //从其他页面点击立即购买跳转到此页 初始化
          if (this.$store.state.goodsInfo.name) {
            let store = "";
            let stopStatus = 1;
            let stopReason = "";
            this.storeList.find((res) => {
              if (res.id == this.formData.storeId) {
                store = res.name;
                stopStatus = res.stopStatus;
                stopReason = res.stopReason;
                return true;
              }
            });
            this.shopCart = Object.assign(
              { num: 1, store, stopStatus, stopReason },
              this.$store.state.goodsInfo
            );
            this.$store.state.goodsInfo = {};
          }
          if (this.shopCart) {
            this.countPrice();
          }
        },
      });
    },
    //查看商品列表
    getGoodsList() {
      //consloe.log(this.formData);
      this.$request.post({
        url: "/ReportApi/business/list",
        params: {
          id: this.formData["storeId"],
          tenantid:localStorage.getItem('tenantInfoid')
        },
        success: (res) => {
          //consloe.log(res)
          var rea = {};
          for (let i = 0; i < res.length; i++) {
            res[i].cost = Number(res[i].cost);
            res[i].profit = Number(res[i].profit);
            res[i].weight = Number(res[i].weight);
          }
          console.log(res);
          rea.list = res;
          rea.total = res.length;
          rea.page = 1;
          this.goodsList = rea.list;
          this.total = rea.total;
          console.log(rea);
        },
      });
    },
    submitText1() {
      if (this.stringRule1 == "") {
        this.$message.warning("请先填写订单号");
        return;
      }
      if (this.houseId == "") {
        this.$message.warning("请先选择店铺");
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      let list = [];
      let isNum = true;
      this.stringRule1.split("\n").forEach((item) => {
        item = item.replace(/\s+/g, "");
        if (!item.match(/^[0-9]*$|\-/)) {
          isNum = false;
        }
        if (item) {
          list.push(item);
        }
      });
      if (!isNum) {
        this.$message.error("格式有误");
        loading.close();
        return;
      }
      let url;
      if (this.model == 4) {
        url = "/bus/shop/getOrderListBySn";
      } else if (this.model == 6) {
        url = "/bus/shop/dyOrderDetail";
      } else {
        url = "bus/shop/getTbOrderListBySn";
      }
      this.$request.post({
        url,
        params: {
          orderNoStr: list.join(","),
          id: this.houseId,
        },
        closeErrInfo: true,
        success: (res) => {
          res.forEach((res, index) => {
            let obj = {
              commonId: "",
              html: '<span style="color:green">信息正确</span>',
              conlist: [
                {
                  addr: [],
                  consignee: res.name,
                  consigneeAddr: res.address,
                  consigneePhone: res.phone,
                  orderNum: res.orderNo,
                  addrStr: "",
                  address: res.address,
                  city: res.city,
                  county: res.county,
                  province: res.province,
                  encryptAddress: res.encryptAddress,
                  encryptName: res.encryptName,
                  encryptPhone: res.encryptPhone,
                  tradeName: res.tradeName,
                  remark: res.remark,
                },
              ],
              goodsIdlist: "",
              goodsName: "",
              goodsNumlist: "",
              status: 1,
              price: "",
              show: false,
              totalNum: 1,
            };
            this.taskList4.push(obj);
          });
          this.stringRule1 = "";
        },
        error: (res) => {
          if (res.indexOf("打单软件服务到期") != -1) {
            var str = "打单软件服务已到期，是否前往续费？";
            this.$confirm(str, "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
              center: true,
            })
              .then(() => {
                window.open(
                  "https://fuwu.taobao.com/ser/detail.htm?spm=a1z13.pc_search_result.1234-fwlb.5.78ec5acaZRnAnk&service_code=ts-21954&tracelog=search&from_key=%E6%98%93%E9%80%9A+%E6%89%93%E5%8D%B0"
                );
              })
              .catch(() => {});
          } else {
            this.$message.error(res);
          }
        },
        finally: (res) => {
          loading.close();
        },
      });
    },
    submitText() {
      if (!this.recipientListStr) {
        this.$message.warning("请先输入收货人信息");
        return;
      }
      //是否进行拼多多请求
      let pddRequest = false;
      let douyinRequest = false;
      let list = [];
      this.recipientListStr.split("\n").forEach((item) => {
        item = item.replace(/,/gi, "，");
        var arr = item.split("，");
        let obj = {};
        try {
          if (arr.length == 5) {
            obj = {
              commonId: "",
              html: "",
              conlist: [
                {
                  addr: [],
                  consignee: arr[1],
                  consigneeAddr: arr[3] + arr[4],
                  consigneePhone:
                    arr[2].indexOf("-") != -1 ? arr[2].split("-")[1] : arr[2],
                  orderNum: arr[0],
                  addrStr: arr[3] + arr[4],
                  address: "",
                  city: "",
                  county: "",
                  province: "",
                },
              ],
              goodsIdlist: "",
              goodsName: "",
              goodsNumlist: "",
              price: "",
              show: false,
              totalNum: 1,
            };
            list.push(obj);
          } else if (arr.length == 4) {
            if (/[a-zA-Z\u4e00-\u9fa5]/.test(arr[0])) {
              obj = {
                commonId: "",
                html: "",
                conlist: [
                  {
                    addr: [],
                    consignee: arr[0],
                    consigneeAddr: arr[2] + arr[3],
                    consigneePhone:
                      arr[1].indexOf("-") != -1 ? arr[1].split("-")[1] : arr[1],
                    orderNum: "",
                    addrStr: arr[2] + arr[3],
                    address: "",
                    city: "",
                    county: "",
                    province: "",
                  },
                ],
                goodsIdlist: "",
                goodsName: "",
                goodsNumlist: "",
                price: "",
                show: false,
                totalNum: 1,
              };
            } else {
              obj = {
                commonId: "",
                html: "",
                conlist: [
                  {
                    addr: [],
                    consignee: arr[1],
                    consigneeAddr: arr[3],
                    consigneePhone:
                      arr[2].indexOf("-") != -1 ? arr[2].split("-")[1] : arr[2],
                    orderNum: arr[0],
                    addrStr: arr[3],
                    address: "",
                    city: "",
                    county: "",
                    province: "",
                  },
                ],
                goodsIdlist: "",
                goodsName: "",
                goodsNumlist: "",
                price: "",
                show: false,
                totalNum: 1,
              };
            }
            list.push(obj);
          } else if (arr.length == 3) {
            let obj = {
              commonId: "",
              html: "",
              conlist: [
                {
                  addr: [],
                  consignee: arr[0],
                  consigneeAddr: arr[2],
                  consigneePhone:
                    arr[1].indexOf("-") != -1 ? arr[1].split("-")[1] : arr[1],
                  orderNum: "",
                  addrStr: arr[2],
                  address: "",
                  city: "",
                  county: "",
                  province: "",
                },
              ],
              goodsIdlist: "",
              goodsName: "",
              goodsNumlist: "",
              price: "",
              show: false,
              totalNum: 1,
            };
            list.push(obj);
          } /*else if(arr.length == 1 && this.platform == 4 && this.houseId){
                        let item = arr[0]
                        let isNum = isNaN(item)
                        if(isNum){
                            this.$message.error('格式有误')
                            return
                        }else{
                            pddRequest = true
                            let obj = {
                                commonId:"",
                                html: "",
                                conlist: [
                                    {
                                        addr:[],
                                        consignee:'',
                                        consigneeAddr:'',
                                        consigneePhone:'',
                                        orderNum:item,
                                        addrStr: '',
                                        address:'',
                                        city:'',
                                        county:'',
                                        province:''
                                    }
                                ],
                                goodsIdlist:"",
                                goodsName:"",
                                goodsNumlist:"",
                                price:"",
                                show:false,
                                totalNum:1
                            };
                            list.push(obj);
                        }
                    }*/ else {
            let numArr = item.match(/[0-9]+/gi);
            var phone = "";
            for (let numItem of numArr) {
              if (/^1[3-9]\d{9}$/.test(numItem)) {
                phone = numItem;
                break;
              }
            }
            let addrArr = item.split(phone);
            let addr = addrArr[1];
            let name = addrArr[0].match(/[a-zA-Z\u4e00-\u9fa5]+/g);
            let orderNum = addrArr[0].split(name)[0];
            let obj = {
              commonId: "",
              html: "",
              conlist: [
                {
                  addr: [],
                  consignee: name[0],
                  consigneeAddr: addr,
                  consigneePhone: phone,
                  orderNum: orderNum ? orderNum : "",
                  addrStr: addr,
                  address: "",
                  city: "",
                  county: "",
                  province: "",
                },
              ],
              goodsIdlist: "",
              goodsName: "",
              goodsNumlist: "",
              price: "",
              show: false,
              totalNum: 1,
            };
            list.push(obj);
          }
        } catch (error) {}
      });
      if (pddRequest || douyinRequest) {
        let order = [];
        list.forEach((res) => {
          order.push(res.conlist[0].orderNum);
        });
        this.$request.post({
          url: "/bus/order/getOrderInfo",
          params: {
            orderNoStr: order.join(","),
            shopId: this.houseId,
          },
          success: (res) => {
            res.forEach((res, index) => {
              list[index].conlist[0].consigneePhone = res.phone;
              list[index].conlist[0].consignee = res.name;
              list[index].conlist[0].province = res.province;
              list[index].conlist[0].city = res.city;
              list[index].conlist[0].consigneeAddr = res.address;
              list[index].conlist[0].county = res.county;
              list[index].html = '<span style="color:green">信息正确</span>';
              list[index].status = 1;
              Object.assign(list[index].conlist[0], res);
            });
            this.taskList4.push(...list);
          },
        });
      } else {
        let address = [];
        list.forEach((res) => {
          address.push(res.conlist[0].consigneeAddr);
        });
        this.$request.post({
          url: "/ReportApi/business/cutaddress",
          params: {
            belongTerrace: this.platform,
            receiveAddress: address[0],
          },
          success: (res) => {
            //consloe.log(res);
            var ress = {
              address: res[0],
              city: res[1],
              county: res[2],
              province: res[3],
              sensitiveStr: res[4],
            };
            //consloe.log(ress);
            var reas = [];
            reas.push(ress);
            //consloe.log(reas);
            reas.forEach((reas, index) => {
              let html = "";
              if (reas.address && reas.city && reas.county && reas.province) {
                html = '<span style="color:green">信息正确</span>';
                list[index].html = html;
                list[index].status = 1;
              } else {
                html = '<span style="color:red">地址有误</span>';
                list[index].html = html;
                list[index].status = 0;
              }
              let phone = list[index].conlist[0].consigneePhone;
              if (!this.$check.isPhone(phone)) {
                html = '<span style="color:red">电话有误</span>';
                list[index].html = html;
                list[index].status = 0;
              }
              if (reas.sensitiveStr) {
                html =
                  '<span style="color:red">请进行修改地址中存在敏感词：' +
                  reas.sensitiveStr +
                  "</span>";
                list[index].html = html;
                list[index].status = 0;
              }
              Object.assign(list[index].conlist[0], reas);
            });
            this.taskList4.push(...list);
          },
        });
      }
      this.recipientListStr = "";
    },
  },
};
</script>

<style lang="scss">
#send_order {
  .el-scrollbar__wrap {
    overflow-x: hidden !important;
    overflow-y: scroll !important;
    margin-right: -16px !important;
    margin-bottom: 0px !important;
  }
}
</style>
<style lang="scss" scoped>
#send_order {
  .send_buy_block {
    margin-bottom: 15px;
    background: #fff;
    padding: 30px;
    width: 100%;
    box-sizing: border-box;
    display: block;
    border-radius: 4px;
    .title {
      font-size: 16px;
      font-weight: bold;
    }
    .list {
      margin-top: 20px;
      ul {
        li {
          cursor: pointer;
          margin-right: 20px;
          color: #666;
          line-height: 40px;
          border: 1px solid #ccc;
          width: 125px;
          text-align: center;
          border-radius: 4px;
          float: left;
          position: relative;
          img {
            position: absolute;
            right: 0px;
            bottom: 0px;
            width: 22px;
          }
          &.red {
            color: #f80;
            border-color: #f80;
          }
        }
      }
    }
    .tip {
      background: #fafafa;
      padding: 10px;
      font-size: 14px;
      line-height: 28px;
      min-height: 157px;
    }
    .remark {
      width: 300px;
      height: 40px;
      margin-top: 30px;
      textarea {
        border-radius: 4px;
        border-color: #efefef;
        padding: 10px;
        width: 100%;
        outline-color: #ff464e;
        resize: none;
      }
      .select_item {
        .el-input {
          display: inline-block;
          width: 206px;
        }
      }
    }
    .packageList {
      margin-top: 20px;
      span {
        font-weight: bold;
        font-size: 20px;
        color: #ff464e;
      }
    }
    .pdd_order_btn {
      margin-top: 10px;
      &.text {
        color: #f80;
        font-size: 16px;
      }
    }
    .upload-demo {
      margin-top: 20px;
      margin-bottom: 20px;
      float: left;
    }
    .btn {
      line-height: 40px;
      width: 150px;
      color: #fff;
      background: #f80;
      padding: 0 20px;
      display: block;
      text-align: center;
      margin-top: 30px;
      cursor: pointer;
      &.display {
        color: #fff;
        background: #ccc;
      }
    }
    .chose_goods_list {
      margin-top: 20px;
      table {
        width: 100%;
        border: 1px solid #efefef;
        border-collapse: collapse;
        tr {
          line-height: 30px;
          color: #666;
          font-size: 14px;
          border-bottom: 1px solid #efefef;
          &:first-child {
            color: #333;
            height: 30px;
            line-height: 30px;
            background: #f6f6f6;
          }
          td {
            border-right: 1px solid #efefef;
            padding-left: 10px;
            flex: 1;
            &:nth-child(3) {
              width: 50px;
            }
          }
        }
        .table_dl {
          dt {
            float: left;
            margin-right: 10px;
          }
        }
      }
      .chose_goods_edit_btn {
        margin-top: 20px;
      }
    }
    .chose_goods {
      width: 100px;
      text-align: center;
      color: #f80;
      font-size: 14px;
      margin-top: 30px;
      cursor: pointer;
    }
    .dont {
      color: #ff464e;
      cursor: pointer;
      font-size: 14px;
      margin-top: 10px;
    }
  }
  .input_put_block {
    .put_item {
      span {
        display: inline-block;
        width: 80px;
        text-align: right;
        margin-right: 10px;
      }
      input {
        border: 1px solid #efefef;
        outline: none;
        width: 360px;
        line-height: 36px;
        padding: 0 5px;
        margin-right: 10px;
        margin-bottom: 20px;
      }
    }
  }
  .chose_goods {
    .left_item {
      width: 890px;
      float: left;
      height: 600px;
      overflow-x: hidden;
      .search_keywrod {
        display: flex;
        margin-bottom: 10px;
        div {
          flex: 1;
          margin-right: 10px;
        }
        .chose {
          flex: 0 1 140px;
          border-radius: 6px;
          border: 1px solid #efefef;
          line-height: 32px;
          text-align: center;
          &.red {
            color: #fff;
            background: #f80;
          }
        }
      }
      .goods_params {
        border: 1px solid #eee;
        border-radius: 4px;
        font-size: 14px;
        color: #666;
        .block {
          line-height: 50px;
          background: #fafafa;
          &:nth-child(1) {
            border-bottom: 1px solid #eee;
            background: #fff;
          }
          .left_info {
            float: left;
            padding-left: 20px;
            padding-right: 15px;
          }
          ul {
            float: left;
            li {
              float: left;
              padding: 0 10px;
              cursor: pointer;
              &.active {
                color: #f80;
                font-weight: bold;
              }
            }
          }
        }
      }
      .goods_list {
        margin-top: 20px;
        margin-bottom: 20px;
        height: 355px;
        .item {
          width: 160px;
          border: 1px solid #eee;
          margin-bottom: 10px;
          margin-left: 10px;
          float: left;
          &:hover {
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
          }
          dl {
            dt {
              width: 150px;
              margin: 0 auto;
              margin-top: 8px;
            }
            dd {
              padding: 0 8px;
              font-size: 12px;
              color: #999;
              &.title {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                line-height: 20px;
              }
              &.other {
                height: 22px;
                .price {
                  color: #f80;
                  font-size: 14px;
                  font-weight: bold;
                  float: left;
                }
                .wei {
                  float: right;
                }
              }
              &.num {
                line-height: 25px;
                color: #333;
                border-top: 1px solid #efefef;
                border-bottom: 1px solid #efefef;
              }
              &.btn {
                border-radius: 4px;
                color: #f80;
                border: 1px solid #f80;
                line-height: 28px;
                width: 120px;
                text-align: center;
                margin: 10px auto;
                cursor: pointer;
                &:hover {
                  background: #f80;
                  color: #fff;
                }
              }
            }
          }
        }
      }
    }
    .right_item {
      width: 250px;
      float: right;
      background: #dbdbdb;
      height: 600px;
      position: relative;
      .bk_item {
        display: flex;
        line-height: 20px;
        font-size: 12px;
        color: #fff;
        background: #333;
        span {
          flex: 1;
          text-align: center;
        }
      }
      .item_list {
        height: 420px;
        .item {
          background: #fff;
          border-left: 1px solid #efefef;
          border-right: 1px solid #efefef;
          padding: 4px 4px;
          margin-bottom: 6px;
          .title {
            line-height: 20px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            margin-bottom: 5px;
          }
        }
        dl {
          dt {
            float: left;
            border: 1px solid #efefef;
            margin-right: 3px;
          }
          dd {
            font-size: 12px;
            float: left;
            color: #666;
            width: 180px;
            .jia {
              float: left;
              width: 15px;
              height: 15px;
              line-height: 15px;
              text-align: center;
              border: 1px solid #efefef;
              margin-right: 5px;
              cursor: pointer;
            }
            .num {
              float: left;
              input {
                margin-right: 5px;
                border: 1px solid #efefef;
                width: 35px;
                height: 15px;
                line-height: 15px;
                text-align: center;
              }
            }
            .price {
              color: #f80;
              float: left;
              margin-left: 10px;
            }
            .del {
              float: right;
              font-size: 20px;
              width: 20px;
              height: 20px;
              cursor: pointer;
            }
            &:last-child {
              font-size: 12px;
              color: #999;
              p {
                color: #333;
                margin-right: 10px;
              }
            }
          }
        }
        .storage {
          clear: both;
          margin-top: 4px;
          span {
            color: #f80;
            border: 1px solid #f80;
            margin-right: 5px;
            border-radius: 2px;
            padding: 2px 2px;
            margin-bottom: 4px;
            float: left;
            font-size: 12px;
          }
        }
      }
      .home_empty_list {
        text-align: center;
        color: #666;
        line-height: 200px;
      }
      .item_b {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        height: 180px;
        color: #666;
        line-height: 25px;
        .b_title {
          background: #333;
          color: #fff;
          width: 230px;
          margin: 0 auto;
          margin-top: 10px;
          border-radius: 4px;
          line-height: 25px;
          text-align: center;
          margin-bottom: 10px;
        }
        .price {
          padding: 0 20px;
          span {
            float: right;
            color: #f80;
          }
        }
        .weight {
          padding: 0 20px;
          span {
            float: right;
            color: #f80;
          }
        }
        .total {
          padding: 0 20px;
          span {
            float: right;
            font-size: 20px;
            font-weight: bold;
            color: #f80;
          }
        }
      }
    }
  }
}
</style>
